import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes =  [
    {
        path: '/',
        component: () => import('@/views/screen/screensInit.vue'),
        name:'screensInit',
    },
    {
        path: '/communityManger',
        component: () => import('@/views/screen/communityManger.vue'),
        name:'communityManger', 
    },
    {
        path: '/carManger',
        component: () => import('@/views/screen/carManger.vue'),
        name:'carManger', 
    },
    {
        path: '/peopleManger',
        component: () => import('@/views/screen/peopleManger.vue'),
        name:'peopleManger', 
    },
    
]
const router = new VueRouter({
    routes
  })
  export default router